// eslint-disable-next-line no-unused-vars
import React, { Component } from "react";
import { ImageString } from "../../../const/assets/ImageString";
import "./Menu.css";

class Menu extends Component {
  onMenuItemClick = (event, item) => {
    if (!this.props.canInteract) {
      return;
    }
    this.setState({ activeItemId: item.id });
    this.props.onMenuItemClick(event, item);
  };

  getPathRender(value) {
    if (value > 0) {
      var arr = [];
      for (var i = 0; i < value; i++) {
        arr.push(<span key={i} className={`path${i + 1}`}></span>);
      }
      return arr;
    }
  }

  render() {
    const { items, activeItemId } = this.props;
    // console.log(activeItemId);

    return (
      <>
        <div className="desktop__logo">
          <img src={ImageString.MENULOGO} alt="Logo" />
        </div>
        <ul className="bottom-icons-nav bottom-icons-nav--withtext">
          {this.props.backButton && (
            <li
              key={"blackButton"}
              className="menuBackButton"
              onClick={(event) => this.props.onBackButtonClick(event)}
            >
              <a style={{ justifyContent: "center" }}>
                <span> &lt; Back</span>
              </a>
            </li>
          )}
          {items.map((item, pos) => (
            <React.Fragment key={pos}>
              {!item.disabled && (
                <li
                  key={item.id}
                  onClick={(event) => this.onMenuItemClick(event, item)}
                >
                  <a className={item.id == activeItemId ? "active" : ""}>
                    <i className={item.class}></i>
                    <span>{item.name}</span>
                  </a>
                </li>
              )}
            </React.Fragment>
          ))}
        </ul>
      </>
    );
  }
}

export default Menu;
