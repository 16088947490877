import { isMobileOnly } from "react-device-detect";
import { HotspotButtonType, HotspotType } from "../../const/fixed/Types";

export let InfoHotspots_AR = {
  "pdf-1": {
    id: "How_to_be_Ergonomic",
    enabled: true,
    name: "How to be Ergonomic",
    desk_style: {
      margin: "-9% -10%",
    },
    mobile_style: {
      margin: "7% -9.4%",
    },
    get style() {
      return isMobileOnly ? this.mobile_style : this.desk_style;
    },
    hotspotType: HotspotType.anchor,
    buttonType: HotspotButtonType.named,
    link: "http://www.africau.edu/images/default/sample.pdf",
  },
  "pdf-2": {
    id: "Best_practices",
    enabled: true,
    name: "2021 Staff Conference Best Practices",
    desk_style: {
      margin: "-9% 6%",
    },
    mobile_style: {
      margin: "-9% -15.4%",
    },
    get style() {
      return isMobileOnly ? this.mobile_style : this.desk_style;
    },
    hotspotType: HotspotType.pdf,
    buttonType: HotspotButtonType.named,
    link: "http://www.africau.edu/images/default/sample.pdf",
  },
  "pdf-3": {
    id: "Program",
    enabled: true,
    name: "Program",
    desk_style: {
      margin: "1% -10%",
    },
    mobile_style: {
      margin: "-9% -36.4%",
    },
    get style() {
      return isMobileOnly ? this.mobile_style : this.desk_style;
    },
    hotspotType: HotspotType.pdf,
    buttonType: HotspotButtonType.named,
    link: "http://www.africau.edu/images/default/sample.pdf",
  },
  "pdf-4": {
    id: "FAQs",
    enabled: true,
    name: "FAQs",
    desk_style: {
      margin: "1% 6%",
    },
    mobile_style: {
      margin: "2% -35.4%",
    },
    get style() {
      return isMobileOnly ? this.mobile_style : this.desk_style;
    },
    hotspotType: HotspotType.pdf,
    buttonType: HotspotButtonType.named,
    link: "http://www.africau.edu/images/default/sample.pdf",
  },
  ITS_Support: {
    id: "ITS_Support",
    enabled: true,
    name: "ITS Support",
    desk_style: {
      margin: "1% -21%",
    },
    mobile_style: {
      margin: "-9% 26.4%",
    },
    get style() {
      return isMobileOnly ? this.mobile_style : this.desk_style;
    },
    hotspotType: HotspotType.chatbot,
    buttonType: HotspotButtonType.named,
  },
};
