import React from "react";
import Firebase, { firestore, auth, firebaseApp } from "../firebase/firebase";
// import swal from "sweetalert";
import moment from "moment";
import { AppString } from "../firebase/AppString";
import { emojis } from "../common/Emoji/emoji";

class CreateArea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      content: "",
      user: {},
      emoji: emojis,
      showEmojiPanel: false,
      showAlert: false,
    };
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    this.authListener();
  }

  authListener() {
    auth.onAuthStateChanged((user) => {
      if (user) {
        this.setState({ user });
      } else {
        this.setState({ user: null });
      }
    });
  }

  onSubmit = (e) => {
    e.preventDefault();

    var date = moment().utcOffset("+05:30").format("hh:mm A DD-MM-YYYY");

    firestore
      .collection(this.props.QNACollection)
      .add({
        title: this.state.user.displayName
          ? this.state.user.displayName
          : this.state.user.email,
        content: this.state.content,
        time: new Date(),
        time2: date,
        timestamp: firebaseApp.firestore.FieldValue.serverTimestamp(),
        status: `${this.props.moderated ? "0" : "1"}`,
      })
      .then(() => {
        this.setState({ content: "" });
        if (this.props.moderated) {
          this.setState(() => ({
            showAlert: true,
          }));
          this.inputRef.current.blur();
          // swal("Question Submitted!", "Your question has been submitted");
        }
      });
  };

  //#region Emoji
  openEmoji = () => {
    this.setState((prev) => ({
      showEmojiPanel: !prev.showEmojiPanel,
    }));
    // let emoji = document.querySelector('.emoji');
    // (emoji.style.display === 'block')
    //   ? emoji.style.display = "none"
    //   : emoji.style.display = "block"
  };
  openEmojiClose = () => {
    this.setState({
      showEmojiPanel: false,
    });
    // let emoji = document.querySelector('.emoji');
    // if (emoji.style.display === 'block') { emoji.style.display = "none" }
  };

  pickEmoji = (emo) => {
    this.setState((prev) => ({
      content: prev.content + emo,
    }));
  };

  //#endregion

  closeSweatAlert = () => {
    this.setState(() => ({
      showAlert: false,
    }));
  };

  render() {
    return (
      <>
        <form
          // className="qa-section__input"
          onSubmit={this.onSubmit}
        >
          <input
            ref={this.inputRef}
            className="form-control-qna"
            name="content"
            onChange={(e) => this.setState({ content: e.currentTarget.value })}
            value={this.state.content}
            required
            placeholder="Enter Your Comment"
            autoCorrect="off"
            autoComplete="off"
            onFocus={this.props.onFocus}
            onBlur={this.props.onBlur}
            onFocus={this.openEmojiClose}
          />
          <div className="input-rightSide">
            <span className="emojiIcon" onClick={this.openEmoji}>
              {" "}
              ☺{" "}
            </span>
            <span className="qna-sendBtn" onClick={(e) => this.onSubmit(e)}>
              <i className="icon-send"></i>
            </span>
            {/* <button className="form-control-qna__btn">
              <i className="icon-send"></i></button> */}
          </div>
        </form>
        {this.state.showEmojiPanel && (
          <div className="emoji">
            {this.state.emoji.map((emo, i) => (
              <a
                key={i}
                onClick={() => {
                  this.pickEmoji(emo);
                }}
              >
                {" "}
                <span role="img">{emo}</span>{" "}
              </a>
            ))}
          </div>
        )}
        {this.state.showAlert && (
          <div className="custom-sweat-alert">
            <div className="custom-sweat-alert-box">
              <div>
                <div id="tit">Question Submitted!</div>
                <div id="des">Your question has been submitted</div>
              </div>
              <button
                style={{
                  textTransform: "unset",
                  minWidth: "6rem",
                  fontSize: "1rem",
                  padding: "0.5rem 1rem",
                }}
                onClick={this.closeSweatAlert}
                className="btn btn-red"
              >
                Okay
              </button>
            </div>
          </div>
        )}
      </>
    );
  }
}
export default CreateArea;
