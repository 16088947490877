import React, { Component } from "react";
import "./Login.css";
import {
  realDB,
  loadUser,
  getUserDetails,
  getOnlineStatus,
  getUserDetailsNew,
} from "../../firebase/firebase";
import hitachi from "./assets/hitachi.png";
import cooling from "./assets/cooling.png";
import loginVideo from "./assets/login.mp4";
import { ImageString } from "../../../const/assets/ImageString";
import "./Login.css";
import { isMobileOnly } from "react-device-detect";

class Login extends Component {
  state = {
    email: "",
    mobile: "",
    college: "",
    name: "",
    passcode: "",
    error: false,
    errorMessage: "",
    _loading: false,
    forceDisable: false,
    forceUpdate: false,
    isLoginForm: true,
  };
  videoRef = React.createRef();

  componentDidMount = () => {
    window.showLoginError = this.showLoginError;
    if (!isMobileOnly) window.addEventListener("click", this.playVideo);
  };
  componentWillUnmount = () => {
    if (!isMobileOnly) window.removeEventListener("click", this.playVideo);
    if (this.videoRef) {
      if (this.videoRef.current) this.videoRef.current.remove();
    }
  };

  onInputChange = (event) => {
    event.preventDefault();
    let value = event.target.value;
    this.setState({ [event.target.name]: value });
  };

  showLoginError = (err) => {
    this.setState({
      error: true,
      errorMessage: err.message ? err.message : err,
    });
  };

  validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  checkPasscode = (userPasscode) => {
    if (userPasscode.trim().toLocaleLowerCase() === "tup8") return true;
    else return false;
  };

  onFormSubmit = async (event) => {
    event.preventDefault();
    try {
      // if (this.state.mobile.trim().toLowerCase().length < 10) {
      //   throw { code: "mobile less than 10" };
      // }
      if (!this.validateEmail(this.state.email.trim().toLocaleLowerCase())) {
        throw { code: "email error" };
      }
      const userEmail = `${this.state.email.trim().toLowerCase()}`;
      console.log(userEmail);
      // const passcode = `${this.state.passcode.trim().toLowerCase()}`;

      // if (passcode !== "tup6") {
      //   throw { code: "Please enter correct passcode!" };
      // }

      const password = `${userEmail}123456`;
      await getOnlineStatus(userEmail);

      await loadUser(
        userEmail,
        password,
        true,
        this.state.name.trim(),
        this.state.college.toLowerCase().trim()
      );

      window.userData = getUserDetailsNew(userEmail);
    } catch (err) {
      this.setState({
        forceDisable: false,
      });
      let error = "";
      switch (err.code) {
        case "auth/wrong-password":
          error = "Invalid username and password.";
          break;
        case "auth/user-not-found":
          error = "* User not registered";
          break;
        case "auth/too-many-requests":
          error =
            "Too many invalid requests, please wait for 60 seconds before retrying";
          break;
        case "NoUserFound":
          error = "* User not registered";
          break;
        case "siggend In":
          error = "Already signed in from another device";
          break;
        case "mobile less than 10":
          error = "* Invalid Mobile Number";
          break;

        case "email error":
          error = "* Invalid Email";
          break;
        default:
          error = err.code;
      }
      this.setState({
        error: true,
        errorMessage: error,
      });
    }
  };

  checkUserStatus = async (userId) => {
    return new Promise((resolve, reject) => {
      if (userId === undefined) {
        reject({
          code: "UserIDWasNull",
          message: "Please try again later",
        });
      } else {
        if (userId === "") {
          reject({
            code: "UserIDWasNull",
            message: "Please try again later",
          });
        }
      }
      var userCheck = realDB.ref("loggedInUser/" + userId);
      userCheck.once("value").then(
        (snapshot) => {
          if (snapshot.exists()) {
            let data = snapshot.val();
            if (data.state === "online") {
              let err = {
                code: "AlreadyLogged",
                message: "User Logged in from another system.",
              };
              reject(err);
            } else {
              resolve();
            }
          } else {
            resolve();
          }
        },
        (err) => {
          reject(err);
        }
      );
    });
  };

  render() {
    if (this.state._loading) {
      return <img alt="loading" src="/images/loader.gif" />;
    }
    return (
      <>
        <section className="landing-page min-height-full">
          {/* <aside
            className="landing-pageBox d-flex justify-content-between align-items-start min-height-full image-bg"
            style={{ backgroundImage: `url(${ImageString.LoginScreen})` }}
          ></aside> */}
          <aside
            className="landing-pageBox d-flex justify-content-between align-items-start min-height-full image-bg"
            style={
              !isMobileOnly
                ? {
                    backgroundImage: `url(${ImageString.LoginScreen})`,
                    backgroundPosition: "left top",
                  }
                : {
                    backgroundImage: `url(${ImageString.LoginScreen})`,
                  }
            }
          >
            {!isMobileOnly && (
              <div className="main3DContainer">
                <div id="child3DContainer">
                  <video
                    className={`TransitionVideo LoginScreenVideo`}
                    width="320"
                    height="240"
                    loop={true}
                    preload="auto"
                    autoPlay={true}
                    muted={true}
                    playsInline={true}
                    ref={this.videoRef}
                    style={{ opacity: 1, left: "0" }}
                  >
                    <source
                      src={"/3dAssets/videos/login.mp4"}
                      type="video/mp4"
                    />
                    Your browser does not support HTML5 video.
                  </video>
                </div>
              </div>
            )}
          </aside>
          <aside className="signinBox min-height-full" style={{ zIndex: "5" }}>
            {this.props.showLoggingIn && (
              <>
                <div className="signinBox__heading__container">
                  <div className="signinBox__heading">
                    <div className="left"></div>
                  </div>
                  <div className="signinBox__heading__gradient"></div>
                </div>
                <br></br>
                <div className="loaderContainer">
                  <img src="/assets/images/Loader.gif" alt="loader"></img>
                  <div>Logging you in...</div>
                </div>
              </>
            )}
            {!this.props.showLoggingIn && (
              <form onSubmit={this.onFormSubmit}>
                <div className="signinBox__heading__container">
                  <div className="signinBox__heading">
                    <div className="left"></div>
                  </div>
                </div>
                {this.props.liveStatus ? (
                  <div className="signinBox__body pd-t70">
                    <div className="form-group mg-b50 br-l">
                      <input
                        type="text"
                        className="form-control login-input"
                        name="name"
                        value={this.state.name}
                        placeholder="ENTER YOUR NAME"
                        onChange={this.onInputChange}
                        required={true}
                        // style={{
                        //   border: "1px solid #D4A847",
                        //   color: "#000",
                        //   fontSize: "0.85rem",
                        // }}
                      />
                    </div>
                    <div className="form-group mg-b50 br-l">
                      <input
                        type="text"
                        className="form-control login-input"
                        name="email"
                        value={this.state.email}
                        placeholder="ENTER YOUR EMAIL"
                        onChange={this.onInputChange}
                        required={true}
                        // style={{
                        //   border: "1px solid #D4A847",
                        //   color: "#000",
                        //   fontSize: "0.85rem",
                        // }}
                      />
                    </div>

                    <div className="form-group mg-b50 br-l">
                      <input
                        type="text"
                        className="form-control login-input"
                        name="college"
                        value={this.state.college}
                        placeholder="ENTER YOUR COLLEGE/ORGANIZATION NAME"
                        onChange={this.onInputChange}
                        required={true}
                        // style={{
                        //   border: "1px solid #D4A847",
                        //   color: "#000",
                        //   fontSize: "0.85rem",
                        // }}
                      />
                    </div>
                    {/* <div className="form-group mg-b50 br-l">
                          <input
                            type="text"
                            className="form-control login-input"
                            name="passcode"
                            value={this.state.passcode}
                            placeholder="ENTER YOUR PASSCODE"
                            onChange={this.onInputChange}
                            required={true}
                           
                          />
                        </div> */}
                    {this.state.error && (
                      <div
                        className="mg-b50"
                        style={{
                          color: "red",
                          fontSize: "0.85rem",
                        }}
                      >
                        {this.state.errorMessage}
                      </div>
                    )}
                    <div className="cntr">
                      <button
                        className="btn btn-md br-l login-btn"
                        disabled={this.state.forceDisable ? true : false}
                      >
                        Login
                      </button>
                    </div>

                    {/* <p
                          style={{
                            marginTop: "2rem",
                            fontSize: isMobileOnly ? "1.2rem" : "1.5rem",
                            textAlign: "center",
                          }}
                        >
                          Already Registered:{" "}
                          <span
                            style={{ color: "blue", cursor: "pointer" }}
                            onClick={() => {
                              this.setState({
                                isLoginForm: true,
                              });
                            }}
                          >
                            Click here
                          </span>
                        </p> */}
                  </div>
                ) : (
                  <div style={{ padding: "2rem 2rem", textAlign: "center" }}>
                    <h2>{this.props.message}</h2>
                  </div>
                )}
              </form>
            )}
          </aside>
        </section>
      </>
    );
  }
}

export default Login;
