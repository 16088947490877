import React, { Component } from "react";
import UserContext from "./UserContext";
import {
  auth,
  updateUserStatus,
  addLoginAnalytics,
  addDisconnectListener,
  getUserDetails,
  getUserDetailsNew,
} from "../../firebase/firebase";

class UserProvider extends Component {
  state = {
    user: null,
  };
  componentDidMount = async () => {
    auth.onAuthStateChanged(async (userAuth) => {
      if (userAuth) {
        this.setState({
          user: userAuth,
        });
        try {
          let Id = userAuth.email;
          Id = Id.replace(/[&\/\\#,+$~%.'":*?<>{}]/g, "");
          Id = Id.toLowerCase();
          const data = await getUserDetailsNew(userAuth.email);
          addLoginAnalytics({ ...userAuth, ...data }).then(async () => {
            await updateUserStatus(Id, userAuth.email);
          });

          addDisconnectListener(userAuth);
          window.app.appConfig(userAuth);
        } catch (err) {
          console.log(err);
        }
        if (localStorage.getItem("viewedIntroOnce")) {
          userAuth.secondTime = true;
        }
      } else {
        localStorage.clear();
        sessionStorage.clear();
        this.setState({
          user: null,
        });
      }
    });
  };
  render() {
    const { user } = this.state;

    return (
      <UserContext.Provider value={user}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}

export default UserProvider;
