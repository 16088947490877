import React from "react";
import { ImageString } from "../../const/assets/ImageString";
import QABox from "./QABox";

class QNA extends React.Component {
  onHeadingClick(event) {
    this.props.onHeadingClick(event);
  }

  render() {
    const {
      QNACollection,
      QNAReplyCollection,
      canReply,
      moderated,
      headerName,
    } = this.props;
    return (
      <div className="second-level-nav">
        <h3
          className="second-level-nav__title has-icon"
          onClick={(event) => this.onHeadingClick(event)}
        >
          {/* <i className="second-level-nav__icon icon-angle-back"></i>  */}
          {headerName ? "CHAT" : "CHAT"}
          {/* <i className="icon-close" style={{ cursor: "pointer" }}></i> */}
          <img
            className="poll-close-btn"
            src={ImageString.ChatClose}
            alt="close"
          ></img>
        </h3>
        <QABox
          QNACollection={QNACollection}
          QNAReplyCollection={QNAReplyCollection}
          canReply={canReply}
          moderated={moderated}
        />
      </div>
    );
  }
}

export default QNA;
