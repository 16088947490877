import React, { useEffect } from "react";
import { useState } from "react";
import "./Gallery.css";
import logo from "./logo.png";
import crossIcon from "./cross.png";

const Background = {
  background: `url(${"/3dAssets/images/breakout.jpg"})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
};

function ImageGallery({ data, addAnalytics, backBtn }) {
  const [popup, setPopup] = useState({
    show: false,
    image: "",
    title: "",
    heading: "",
    desc: "",
  });
  useEffect(() => {
    addAnalytics(true);
  }, []);
  return (
    <div className="gallery" style={{ ...Background }}>
      <div className="gallery__container">
        <div className="gallery__header">
          <h3>{data.title}</h3>
          <img
            className="back-btn"
            src={crossIcon}
            onClick={(e) => backBtn(e)}
            alt="x"
          />
        </div>
        <div className="gallery__area">
          <div className="gallery__row">
            {popup.show && (
              <div className="gallery__popup">
                <div className="inner__popup">
                  <div className="image__left">
                    <img src={popup.image} alt="" />
                    <img
                      onClick={() => {
                        setPopup({
                          show: false,
                          image: "",
                          title: "",
                          heading: "",
                          desc: "",
                        });
                      }}
                      src="/galleryImages/close.svg"
                      alt=""
                      className="closePopup"
                    />
                  </div>
                  <div className="desc__right">
                    <p style={{ fontWeight: "700" }}>{popup.title}</p>
                    <h2>{popup.heading}</h2>
                    <p>
                      {popup.desc.split("/n").map((str, pos) => {
                        return (
                          <React.Fragment key={pos}>
                            {str}
                            <br />
                          </React.Fragment>
                        );
                      })}
                    </p>
                  </div>
                </div>
              </div>
            )}
            {data.arr.map((item, pos) => {
              return (
                <div className="box" key={pos}>
                  <p>{item.title}</p>
                  <div
                    className="box__img"
                    onClick={() => {
                      window.open(item.link, "_blank");

                      // setPopup({
                      //   show: true,
                      //   image: item.label,
                      //   title: item.title,
                      //   heading: item.heading,
                      //   desc: item.desc,
                      // });
                    }}
                  >
                    <div
                      className="box__img__bg"
                      style={{
                        backgroundImage: `url(${item.path})`,
                        backgroundSize: `${data.bgSize}`,
                      }}
                    ></div>
                    <div className="hover__bg">
                      <div className="sub_hover_bg">
                        {/* <img src={item.label} alt="" /> */}
                        {/* <h3>{item.hover_data.title}</h3> */}
                        <h4>{item.hover_data.description}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <h4 className="header-title">{data.description}</h4>
        </div>
      </div>
    </div>
  );
}

export default ImageGallery;
