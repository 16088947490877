import React from "react";
import Firebase, { firestore, auth, firebaseApp } from "../firebase/firebase";
// import swal from "sweetalert";
import moment from "moment";
import { AppString } from "../firebase/AppString";

class CreateArea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      content: "",
      user: {},
      showAlert: false,
    };
    this.intervalRef = React.createRef();
  }

  componentDidMount() {
    this.authListener();
  }
  timerListener = () => {
    this.intervalRef.current = setInterval(() => {
      this.setState((prev) => ({ timeLeft: prev.timeLeft - 1 }));
    }, 1000);
  };
  authListener() {
    auth.onAuthStateChanged((user) => {
      if (user) {
        this.setState({ user });
      } else {
        this.setState({ user: null });
      }
    });
  }
  onSubmit(e) {
    e.preventDefault();

    var date = moment().utcOffset("+10:00").format("hh:mm A DD-MM-YYYY");

    firestore
      .collection(this.props.QNACollection)
      .add({
        title: this.state.user.displayName
          ? this.state.user.displayName
          : this.state.user.email,
        content: this.state.content,
        time: new Date(),
        time2: date,
        timestamp: firebaseApp.firestore.FieldValue.serverTimestamp(),
        status: `${this.props.moderated ? "0" : "1"}`,
      })
      .then(() => {
        this.setState({
          content: "",
        });
        this.props.timerData.setMessageSent((prev) => prev + 1);
        if (this.props.moderated) {
          this.setState(() => ({
            showAlert: true,
          }));
          // swal("Question Submitted!", "Your question has been submitted");
        }
      });
  }
  closeSweatAlert = () => {
    this.setState(() => ({
      showAlert: false,
    }));
  };
  render() {
    return (
      <>
        <form className="qa-section__input" onSubmit={this.onSubmit.bind(this)}>
          <input
            className="form-control-qna"
            name="content"
            disabled={this.props.timerData.messageSent === 5}
            onChange={(e) => this.setState({ content: e.currentTarget.value })}
            value={this.state.content}
            required
            placeholder={
              this.props.timerData.messageSent === 5
                ? `Timeout ${this.props.timerData.timeLeft}s ...`
                : "Enter Your Question"
            }
            autoCorrect="off"
            autoComplete="off"
            onFocus={this.props.onFocus}
            onBlur={this.props.onBlur}
          />
          <button className="form-control-qna__btn">
            <i className="icon-send"></i>
          </button>
        </form>
        {this.state.showAlert && (
          <div className="custom-sweat-alert">
            <div className="custom-sweat-alert-box">
              <div>
                <div id="tit">Question Submitted!</div>
                <div id="des">Your question has been submitted</div>
              </div>
              <button
                style={{
                  textTransform: "unset",
                  minWidth: "6rem",
                  fontSize: "1rem",
                  padding: "0.5rem 1rem",
                }}
                onClick={this.closeSweatAlert}
                className="btn btn-red"
              >
                Okay
              </button>
            </div>
          </div>
        )}
      </>
    );
  }
}
export default CreateArea;
