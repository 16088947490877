import { VideoType } from "../../const/fixed/Types";

export const VideoPlayerData = {
  "video-1": {
    enabled: true,
    type: VideoType.vimeo,
    id: "video-1",
    link: "https://vimeo.com/593768174/a5fc45cff7",
    name: "Together, apart",
    videoCode: "593768174",
    description: "",
  },
  "video-2": {
    enabled: true,
    type: VideoType.vimeo,
    id: "video-2",
    link: "https://vimeo.com/593767169/2b409d2bd3",
    name: "Loreal FREEDOM GO TO BEYOND",
    videoCode: "593767169",
    description: "",
  },
  "video-3": {
    enabled: true,
    type: VideoType.vimeo,
    id: "video-3",
    link: "https://vimeo.com/593767060/7214b3e512",
    name: "Diversity and Inclusion",
    videoCode: "593767060",
    description: "",
  },
  "video-4": {
    enabled: true,
    type: VideoType.vimeo,
    id: "video-4",
    link: "https://vimeo.com/593767023/830a0564d2",
    name: "Brandstorm Video 2",
    videoCode: "593767023",
    description: "",
  },
  "video-5": {
    enabled: true,
    type: VideoType.vimeo,
    id: "video-5",
    link: "https://vimeo.com/593766943/58616cb99c",
    name: "#WeAreLoreal",
    videoCode: "593766943",
    description: "",
  },
};

export const PDFPlayerData = {
  "pdf-1": {
    id: "pdf-1",
    link: "/assets/content/Beauty Innovations You Must Know!.pdf",
    name: "Beauty Innovations You Must Know!",
    thumbnail: "",
    description: "",
  },
  "pdf-2": {
    id: "pdf-2",
    link: "/assets/content/Tech is everywhere.pdf",
    name: "Tech is everywhere",
    thumbnail: "",
    description: "",
  },
  "pdf-3": {
    id: "pdf-3",
    link: "/assets/content/Why you should join L’Oréal Beauty Tech adventure.pdf",
    name: "Why you should join L’Oréal Beauty Tech adventure",
    thumbnail: "",
    description: "",
  },
};

export const PDFPlayerDataSec = {
  "pdf-1": {
    id: "pdf-1",
    link: "/assets/content/Designed by you - Career paths at L’Oréal.pdf",
    name: "Designed by you - Career paths at L’Oréal",
    thumbnail: "",
    description: "",
  },
  "pdf-2": {
    id: "pdf-2",
    link: "/assets/content/MT Batch 2020.pdf",
    name: "2020 MT testimonials",
    thumbnail: "",
    description: "",
  },
};
